import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Radio,
  RadioGroup,
  TextField,
  Divider,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { cartTotal, cartTotalPrescriptionFee, sleep } from "../../utils/util";
import { Controller, useForm } from "react-hook-form";
import { loginUser } from "../../features/auth/authSlice";
import { setUser } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";

import {
  checkoutApi,
  applyCouponCodeApi,
  generateAccessToken,
  paypalCreateOrder,
  capturePayment,
  updateOrderStatusWithDetail,
} from "../../features/product/productApi";
import {
  PayPalScriptProvider,
  PayPalCardFieldsProvider,
  PayPalNumberField,
  PayPalExpiryField,
  PayPalCVVField,
  usePayPalCardFields,
  PayPalButtons,
} from "@paypal/react-paypal-js";

import { paypalDetails } from "../../config/config";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const CheckoutPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [billingSameAsDelivery, setBillingSameAsDelivery] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [totalPrescriptionFee, setTotalPrescriptionFee] = useState("");
  const [showVoucher, setShowVoucher] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [checkoutResponse, setCheckoutResponse] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("paypal");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [userId, setUserId] = useState(user);
  const [showPassword, setShowPassword] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponDetails, setCouponDetails] = useState("");
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const getCartData = async () => {
    const cartData = window.localStorage.getItem("cartData");
    const voucherData = window.localStorage.getItem("voucherData");
    if (cartData) {
      const cartD = JSON.parse(cartData);
      if (cartD?.length > 0) {
        setCartItems(cartD);
        let total = cartTotal(cartD);
        setTotalAmount(total?.toFixed(2));
        let totalPF = cartTotalPrescriptionFee(cartD);
        setTotalPrescriptionFee(totalPF?.toFixed(2));
      }
    }
    if (voucherData) {
      const voucher = JSON.parse(voucherData);
      setVoucherCode(voucher?.code);
      applyCouponCode(voucher?.code);
    }
  };

  useEffect(() => {
    getCartData();
  }, []);

  useEffect(() => {
    window.addEventListener("cartUpdate", () => {
      getCartData();
    });
  }, []);

  const handleBillingAddressChange = (event) => {
    setBillingSameAsDelivery(event.target.value === "same");
  };

  const toggleVoucher = (show) => {
    setShowVoucher(!show);
  };

  const {
    control,
    getValues,
    setError,
    setValue,clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    const data = {
      email: getValues("email"),
      password: getValues("password"),
    };
    setLoading(true);
    try {
      const response = await dispatch(loginUser(data)).unwrap();
      if (response?.status) {
        setLoading(false);
        await dispatch(setUser(response.data));
        setUserId(response?.data);
      } else {
        setLoading(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("Failed to login"));
    }
  };

  const applyCouponCode = async (voucherCode) => {
    if (voucherCode !== "") {
      const couponData = await applyCouponCodeApi({
        coupon: voucherCode,
        cartTotal: (totalAmount - totalPrescriptionFee - 0).toFixed(2),
      });
      if (couponData?.status) {
        setCouponDetails(couponData?.data);
        window.localStorage.setItem(
          "voucherData",
          JSON.stringify(couponData?.data)
        );
        if (couponData?.data?.discount_type === "fixed") {
          setDiscountAmount(couponData?.data?.discount_amount);
        } else {
          const discount =
            (totalAmount * couponData?.data?.discount_amount) / 100;
          setDiscountAmount(discount?.toFixed(2));
        }
      } else {
        setDiscountAmount(0);
        setVoucherCode("");
        window.localStorage.removeItem("voucherData");
      }
    } else {
      toast.error(`${t("The code field is required")}`);
    }
  };

  const [errorMessages] = useState({
    email: t('Username or email address is required'),
    first_name: t('First name is required'),
    last_name: t('Last name is required'),
    address: t('Address is required'),
    house_no: t('House Number is required'),
    postal_code: t('Postal code is required'),
    city: t('City is required'),
    phone: t('Phone is required'),
  })

  const handleChange = (e) => {
    setValue(e.target.name,e.target.value)
    if(e.target.value === ''){
      setError(e.target.name, {
        message: errorMessages?.[e.target.name]
      })
    }else{
      clearErrors(e.target.name)
    }
  }

  const checkoutData = async () => {
    const email = userId?.email || getValues("email");
    let r = 0;
    if (email === "" || email === undefined || email === null) {
      setError('email', {
        message: t('Username or email address is required')
      })
      r = 1;
    }
    const fName = getValues("first_name") || userId?.default_address?.first_name
    if (fName === "" || fName === undefined || fName === null) {
      setError('first_name', {
        message: t("First name is required")
      })
      r = 1;
    }
    const lName = getValues("last_name") || userId?.default_address?.last_name
    if (lName === "" || lName === undefined || lName === null) {
      setError('last_name', {
        message: t("Last name is required")
      })
      r = 1;
    }
    const add = getValues("address") || userId?.default_address?.address
    if (add === "" || add === undefined || add === null) {
      setError('address', {
        message: t("Address is required")
      })
      r = 1;
    }
    const hn = getValues("house_no") || userId?.default_address?.house_no
    if (hn === "" || hn === undefined || hn === null) {
      setError('house_no', {
        message: t("House Number is required")
      })
      r = 1;
    }
    const pCode = getValues("postal_code") || userId?.default_address?.postal_code
    if (pCode === "" || pCode === undefined || pCode === null) {
      setError('postal_code', {
        message: t("Postal code is required")
      })
      r = 1;
    }
    const cit = getValues("city") || userId?.default_address?.city
    if (cit === "" || cit === undefined || cit === null) {
      setError('city', {
        message: t("City is required")
      })
      r = 1;
    }
    const phone = getValues("phone") || userId?.default_address?.phone
    if (phone === "" || phone === undefined || phone === null) {
      setError('phone', {
        message: t("Phone is required")
      })
      r = 1;
    }
    if (r === 1) {
      toast.error(`${t('Please fill all the required details')}`)
      return false;
    }
    const shippingAddress = {
      country: getValues("country"),
      first_name:
        getValues("first_name") || userId?.default_address?.first_name,
      last_name: getValues("last_name") || userId?.default_address?.last_name,
      address: getValues("address") || userId?.default_address?.address,
      house_no: getValues("house_no") || userId?.default_address?.house_no,
      address_additional: getValues("address_additional"),
      postal_code:
        getValues("postal_code") || userId?.default_address?.postal_code,
      city: getValues("city") || userId?.default_address?.city,
      phone: getValues("phone") || userId?.default_address?.phone,
      dob: getValues("dob") || format(new Date(), "yyyy-MM-dd"),
    };
    const billingAddress = {
      address: getValues("billing_address"),
      house_no: getValues("billing_house_no"),
      address_additional: getValues("billing_address_additional"),
      postal_code: getValues("billing_postal_code"),
      city: getValues("billing_city"),
    };
    if (getValues("billing_address") === "" && !billingSameAsDelivery) {
      toast.error(`${t("Please add billing address")}`);
      return {};
    }
    const cartData = window.localStorage.getItem("cartData");
    const voucherData = window.localStorage.getItem("voucherData");
    let discountAm = 0;

    if (cartData) {
      const cD = JSON.parse(cartData);
      const vD = JSON.parse(voucherData);
      let total = cartTotal(cD);
      let totalPF = cartTotalPrescriptionFee(cD);
      if (
        vD?.discount_type !== undefined &&
        vD?.discount_type !== null &&
        vD?.discount_type !== ""
      ) {
        if (vD?.discount_type === "fixed") {
          discountAm = vD?.discount_amount;
        } else {
          const discount = (total * vD?.discount_amount) / 100;
          discountAm = discount?.toFixed(2);
        }
      }
      setTotalPrescriptionFee(totalPF?.toFixed(2));
      const data = {
        shipping_address: JSON.stringify(shippingAddress),
        billing_address: billingSameAsDelivery
          ? JSON.stringify(shippingAddress)
          : JSON.stringify(billingAddress),
        sub_total_amount: (total - totalPF - 0)?.toFixed(2),
        prescription_fee: totalPF?.toFixed(2),
        shipping_cost: 0,
        discount_amount: discountAm ? discountAm : 0,
        total_amount: (total - discountAm - 0)?.toFixed(2),
        coupon_id: couponDetails?.id,
        coupon_code: couponDetails?.code ? couponDetails?.code : voucherCode,
        items: cD,
        discountData: vD,
        email: userId?.email || getValues("email"),
        payment_method: paymentMethod,
        accept_terms: acceptTerms,
        shipping_method: "standard",
      };
      return data;
    } else {
      toast.error(`${t("Something went worng. Please try again")}.`);
      return {};
    }
  };
  const [isPaying, setIsPaying] = useState(false);

  const handleClickPayNow = async () => {
    // if (paymentMethod === "credit_card") {
    //   document.getElementById("cardPayment").click();
    // }

    if (paymentMethod === "credit_card") {
      document.getElementById("cardPayment").click();
      return false;
    }
    const data = await checkoutData();
    if (data) {
      setIsPaying(true);
      const checkoutResp = await checkoutApi(data);
      if (checkoutResp?.status) {
        setCheckoutResponse(checkoutResp?.data);
        window.localStorage.setItem(
          "checkOutResponse",
          JSON.stringify(checkoutResp?.data)
        );
        if (
          paymentMethod === "klarna" &&
          checkoutResp?.data?.orderPayment?.redirect_url !== "" &&
          checkoutResp?.data?.orderPayment?.redirect_url !== null &&
          checkoutResp?.data?.orderPayment?.redirect_url !== undefined
        ) {
          window.localStorage.setItem("oId", checkoutResp?.data?.order_id);
          window.location.href = checkoutResp?.data?.orderPayment?.redirect_url;
        }
        if (
          paymentMethod === "stripe" &&
          checkoutResp?.data?.orderPayment?.url !== "" &&
          checkoutResp?.data?.orderPayment?.url !== null &&
          checkoutResp?.data?.orderPayment?.url !== undefined
        ) {
          window.localStorage.setItem("oId", checkoutResp?.data?.order_id);
          window.location.href = checkoutResp?.data?.orderPayment?.url;
        }
        setIsPaying(false);
      } else {
        toast.error(checkoutResp?.message)
        setIsPaying(false);
      }
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e?.target?.value);
  };

  const orderDataForPaypal = async () => {
    const data = await checkoutData();
    if (data) {
      const checkoutResp = await checkoutApi(data);
      if (checkoutResp?.status) {
        setCheckoutResponse(checkoutResp?.data);
        window.localStorage.setItem(
          "checkOutResponse",
          JSON.stringify(checkoutResp?.data)
        );
        //const shippingAddress = JSON.parse(data?.shipping_address);
        const orderItems = data?.items?.map((item) => {
          return {
            name: item?.productName,
            unit_amount: {
              currency_code: "EUR",
              value: parseFloat(item?.productPrice)?.toFixed(2),
            },
            quantity: item?.quantity,
          };
        });
        orderItems?.push({
          name: "Prescription Fee",
          unit_amount: {
            currency_code: "EUR",
            value: parseFloat(data?.prescription_fee)?.toFixed(2),
          },
          quantity: 1,
        });

        let discount = {
          currency_code: "EUR",
          value:
            data?.discount_amount && data?.discount_amount > 0
              ? parseFloat(data?.discount_amount)
              : "0",
        };
        const netAmount =
          data?.discount_amount !== "" &&
          data?.discount_amount !== null &&
          data?.discount_amount > 0
            ? parseFloat(data?.total_amount)
            : data?.total_amount;
        const itemTotal =
          data?.discount_amount !== "" &&
          data?.discount_amount !== null &&
          data?.discount_amount > 0
            ? parseFloat(data?.total_amount) + parseFloat(data?.discount_amount)
            : parseFloat(data?.total_amount);

        const final = {
          intent: "CAPTURE",
          purchase_currency: "EUR",
          purchase_units: [
            {
              intent: "capture",
              reference_id: checkoutResp?.data?.order_id,
              amount: {
                currency_code: "EUR",
                value: parseFloat(netAmount)?.toFixed(2),
                breakdown: {
                  item_total: {
                    currency_code: "EUR",
                    value: parseFloat(itemTotal)?.toFixed(2),
                  },
                  // handling: {
                  //   currency_code: "EUR",
                  //   value: parseFloat(data?.prescription_fee)?.toFixed(2),
                  // },
                  discount: discount,
                },
              },
              items: orderItems,
            },
          ],
          payer: {
            name: {
              given_name:
                getValues("first_name") || userId?.default_address?.first_name,
              surname:
                getValues("last_name") || userId?.default_address?.last_name,
            },
            address: {
              address_line_1:
                getValues("address") || userId?.default_address?.address,
              admin_area_2: getValues("address_additional"),
              admin_area_1:
                getValues("house_no") || userId?.default_address?.house_n,
              postal_code:
                getValues("postal_code") ||
                userId?.default_address?.postal_code,
              country_code: "GB",
            },
          },
          // billing_address: {
          //   given_name: shippingAddress?.first_name,
          //   family_name: shippingAddress?.last_name,
          //   email: data?.email,
          //   street_address: shippingAddress?.address,
          //   street_address2: shippingAddress?.address_additional,
          //   house_extension: shippingAddress?.house_no,
          //   postal_code: shippingAddress?.postal_code,
          //   city: shippingAddress?.city,
          //   phone: shippingAddress?.phone,
          //   country: "GB",
          // }
        };
        return final;
      } else {
        toast.error(checkoutResp?.message);
      }
    }
    return false;
  };

  const createOrder = async () => {
    try {
      const d = await orderDataForPaypal();
      if (d) {
        const token = await generateAccessToken();
        const data = await paypalCreateOrder(token, d);
        if (data?.id) {
          return data?.id;
        } else {
          const errorDetail = data?.details?.[0];
          const errorMessage = errorDetail
            ? `${errorDetail.issue} ${errorDetail.description} (${data.debug_id})`
            : JSON.stringify(data);

          throw new Error(errorMessage);
        }
      }
    } catch (error) {
      toast.error(`Could not initiate PayPal Checkout...${error}`);
      return `Could not initiate PayPal Checkout...${error}`;
    }
    return false;
  };

  const onApprove = async (data) => {
    try {
      const orderResponse = await capturePayment(data?.orderID);
      let tokenData = "";
      let oId = "";
      const chData = window.localStorage.getItem("checkOutResponse");
      if (chData) {
        const ch = JSON.parse(chData);
        tokenData = ch?.token;
        oId = ch?.order_id;
        window.localStorage.setItem("tok", ch?.token);
      }
      const transaction =
        orderResponse?.purchase_units?.[0]?.payments?.captures?.[0] ||
        orderResponse?.purchase_units?.[0]?.payments?.authorizations?.[0];
      const errorDetail = orderResponse?.details?.[0];
      if (errorDetail || !transaction || transaction.status === "DECLINED") {
        // (2) Other non-recoverable errors -> Show a failure message
        let errorMessage;
        if (transaction) {
          errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
        } else if (errorDetail) {
          errorMessage = `${errorDetail.description} (${orderResponse.debug_id})`;
        } else {
          errorMessage = JSON.stringify(orderResponse);
        }
        setIsPaying(false);
        throw new Error(errorMessage);
      } else {
        const token = tokenData || checkoutResponse?.token;
        const finalData = {
          order_id: orderResponse?.purchase_units?.[0]?.reference_id || oId,
          total_amount:
            orderResponse?.purchase_units?.[0]?.payments?.captures?.[0]?.amount
              ?.value,
          payment_status: orderResponse?.status,
          transaction_id: orderResponse?.id,
          paypal_capture_id:
            orderResponse?.purchase_units?.[0]?.payments?.captures?.[0]?.id,
          transaction_logs: JSON.stringify(orderResponse),
        };
        const updateOrder = await updateOrderStatusWithDetail(token, finalData);
        if (
          updateOrder?.status &&
          orderResponse?.status?.toLowerCase() === "completed"
        ) {
          window.localStorage.removeItem("cartData");
          window.localStorage.removeItem("checkOutResponse");
          window.localStorage.removeItem("voucherData");
          window.dispatchEvent(new Event("cartUpdate"));
          await sleep(1000);
          window.localStorage.setItem(
            "oId",
            orderResponse?.purchase_units?.[0]?.reference_id || oId
          );
          navigate("/order-success");
          setIsPaying(false);
        }
      }
    } catch (error) {
      toast.error(`Sorry, your transaction could not be processed...${error}`);
      setIsPaying(false);
      return `Sorry, your transaction could not be processed...${error}`;
    }
  };

  const onError = async (data, actions) => {
    var errorDetail = Array.isArray(data.details) && data.details[0];
    if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
      setIsPaying(false);
      return actions.restart(); // Recoverable state, per:
      // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
    }

    if (errorDetail) {
      var msg = "Sorry, your transaction could not be processed.";
      if (errorDetail.description) msg += "\n\n" + errorDetail.description;
      if (data.debug_id) msg += " (" + data.debug_id + ")";
      setIsPaying(false);
      return alert(msg); // Show a failure message (try to avoid alerts in production environments)
    }
  };

  const SubmitPayment = ({ isPaying, setIsPaying }) => {
    const { cardFieldsForm } = usePayPalCardFields();
    const billingAddress = {
      countryCode: "GB",
      firstName: getValues("first_name") || userId?.default_address?.first_name,
      lastName: getValues("last_name") || userId?.default_address?.last_name,
      addressLine1: getValues("address") || userId?.default_address?.address,
      adminArea1: getValues("house_no") || userId?.default_address?.house_no,
      addressLine2: getValues("address_additional"),
      postalCode:
        getValues("postal_code") || userId?.default_address?.postal_code,
      adminArea2: getValues("city") || userId?.default_address?.city,
    };
    const handleClick = async () => {
      if (!cardFieldsForm) {
        const childErrorMessage = `${t("Something went worng. Please try again")}.`;
        throw new Error(childErrorMessage);
      }
      const formState = await cardFieldsForm.getState();
      if (!formState.isFormValid) {
        toast.error(`${t("Something went worng. Please try again")}.`);
        return false;
      }
      setIsPaying(true);
      await sleep(1000);
      cardFieldsForm.submit({ billingAddress }).catch((err) => {
        console.log("here", err);
        // if(err?.message !== '' && err?.message !== null && err?.message !== undefined){
        //   toast.error(`${t("Payment not done. Please check the card details again.")}`);
        // }
        setIsPaying(false);
        return false;
      });
    };
    return (
      <button
        className={isPaying ? "btn" : "btn btn-primary"}
        style={{ float: "right", opacity: 0, width: "0px", height: "0px" }}
        onClick={handleClick}
        id="cardPayment"
      >
        {isPaying ? <div className="spinner tiny" /> : "Pay Now"}
      </button>
    );
  };

  return (
    <Box className="checkout_wrap">
      <Box className="mobile_checkout_logo">
        <a href="/">
          <img src="/images/apotheke-premium-logo.webp" alt="" />
        </a>
      </Box>

      {/* Left Side - Form */}
      {isPaying && (
        <Box className="progressOverlay">
          <CircularProgress />
        </Box>
      )}
      <Grid className="checkout_form">
        <Box className="checkout_logo">
          <a href="/">
            <img src="/images/apotheke-premium-logo.webp" alt="" />
          </a>
        </Box>
        <Box>
          {(userId?.id === "" ||
            userId?.id === undefined ||
            userId?.id === null) && (
            <Box className="checkout_login">
              <Box className="checkout_login_row">
                <Typography
                  variant="h4"
                  sx={{ fontSize: "1.07143rem", fontWeight: 700 }}
                >
                  {t("Customer Information")}
                </Typography>
                <Box>
                  <Typography variant="body1" sx={{ fontSize: "0.857143rem" }}>
                    {t("Already have an account?")}{" "}
                    <Button
                      sx={{
                        padding: "0",
                        color: "#0f3f83",
                        fontWeight: 700,
                        background: "none !important",
                        textTransform: "none",
                        minWidth: 0,
                      }}
                      variant="text"
                      onClick={(e) => setShowLogin(!showLogin)}
                    >
                      {t("Log in")}
                    </Button>
                  </Typography>
                </Box>
              </Box>
              {showLogin ? (
                <>
                  <Box noValidate autoComplete="off" className="common_form">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t("Username or email address is required"),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t("Username or Email Address")}
                          fullWidth
                          margin="normal"
                          error={!!errors.email}
                          helperText={errors.email?.message}
                        />
                      )}
                    />
                    <Box sx={{ display: "flex", gap: "20px", width: "100%" }}>
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{ required: t("Password is required") }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("Password")}
                            type={showPassword ? "text" : "Password"}
                            fullWidth
                            margin="normal"
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label={t("Toggle password visibility")}
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                      <Button
                        sx={{
                          width: "40%",
                          background: "#0f3f83 !important",
                          boxShadow: "none !important",
                          fontSize: "1rem",
                          padding: "6px 8px",
                          textTransform: "none",
                          fontWeight: 700,
                        }}
                        variant="contained"
                        size="large"
                        onClick={onSubmit}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : t("Log in")}
                      </Button>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box className="common_form">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("Username or email address is required"),
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("Username or Email Address")}
                        fullWidth
                        onChange={(e)=>handleChange(e)}
                        margin="normal"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
          )}
          <Typography
            variant="h5"
            sx={{
              fontSize: "1.1667rem",
              fontWeight: 700,
              paddingBottom: "20px",
            }}
          >
            {t("Delivery Address")}
          </Typography>
          <Box className="common_form" sx={{ flexDirection: "column" }}>
            <Box className="country_select">
              <FormControl>
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: t("Country is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      defaultValue="Deutschland"
                      {...field}
                      label={t("Country or Region")}
                      fullWidth
                      required
                      disabled
                      error={!!errors.country}
                      helperText={errors.country?.message}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box className="input_container">
              <Box sx={{ width: "100%" }}>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{
                    required: t("First name is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      defaultValue={userId?.default_address?.first_name || ""}
                      {...field}
                      onChange={(e)=>handleChange(e)}
                      label={t("First name")}
                      fullWidth
                      required
                      error={!!errors.first_name}
                      helperText={errors.first_name?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{
                    required: t("Last name is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      defaultValue={userId?.default_address?.last_name || ""}
                      {...field}
                      onChange={(e)=>handleChange(e)}
                      label={t("Last name")}
                      fullWidth
                      required
                      error={!!errors.last_name}
                      helperText={errors.last_name?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box className="address_container">
              <Box className="input_field">
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: t("Address is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      defaultValue={userId?.default_address?.address || ""}
                      {...field}
                      onChange={(e)=>handleChange(e)}
                      label={t("Address")}
                      fullWidth
                      required
                      error={!!errors.address}
                      helperText={errors.address?.message}
                    />
                  )}
                />
              </Box>
              <Box className="input_field">
                <Controller
                  name="house_no"
                  control={control}
                  rules={{
                    required: t("House Number is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      defaultValue={userId?.default_address?.house_no || ""}
                      {...field}
                      onChange={(e)=>handleChange(e)}
                      label={t("House Number")}
                      fullWidth
                      required
                      error={!!errors.house_no}
                      helperText={errors.house_no?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box>
              <Controller
                name="address_additional"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("Address Addition")}
                    fullWidth
                    margin="normal"
                    error={!!errors.address_additional}
                    helperText={errors.address_additional?.message}
                  />
                )}
              />
            </Box>
            <Box className="input_container">
              <Box sx={{ width: "100%" }}>
                <Controller
                  name="postal_code"
                  control={control}
                  rules={{
                    required: t("Postal code is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      defaultValue={userId?.default_address?.postal_code || ""}
                      {...field}
                      onChange={(e)=>handleChange(e)}
                      label={t("Postal Code")}
                      fullWidth
                      required
                      error={!!errors.postal_code}
                      helperText={errors.postal_code?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: t("City is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      defaultValue={userId?.default_address?.city || ""}
                      {...field}
                      onChange={(e)=>handleChange(e)}
                      label={t("City")}
                      fullWidth
                      required
                      error={!!errors.city}
                      helperText={errors.city?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: t("Telephone is required"),
                }}
                render={({ field }) => (
                  <TextField
                    defaultValue={userId?.default_address?.phone || ""}
                    {...field}
                    onChange={(e)=>handleChange(e)}
                    label={t("Telephone")}
                    fullWidth
                    required
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="dob"
                control={control}
                defaultValue={format(new Date(), "yyyy-MM-dd") || ""}
                rules={{
                  required: t("Birth date is required"),
                }}
                render={({ field }) => (
                  <TextField
                    className="calendarIcon"
                    {...field}
                    label={t("Birth Date")}
                    type="date"
                    fullWidth
                    required
                    error={!!errors.dob}
                    helperText={errors.dob?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>
          </Box>

          <Typography
            variant="h5"
            sx={{ fontSize: "1.1667rem", fontWeight: 700, margin: "20px 0" }}
          >
            {t("Billing Address")}
          </Typography>
          <FormControl component="fieldset" className="shipping_radio_group">
            <RadioGroup
              value={billingSameAsDelivery ? "same" : "different"}
              onChange={handleBillingAddressChange}
            >
              <FormControlLabel
                value="same"
                control={<Radio />}
                label={t("Same as Delivery Address")}
              />
              {/* <FormControlLabel
                value="different"
                control={<Radio />}
                label={t("Use a Different Address")}
              /> */}
            </RadioGroup>
          </FormControl>

          {!billingSameAsDelivery && (
            <Grid className="address_addedit_form" container spacing={2.5}>
              <Grid item xs={12} sm={12} md={8}>
                <Controller
                  name="billing_address"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("Address")}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.billing_address}
                      helperText={errors.billing_address?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Controller
                  name="billing_house_no"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("House Number")}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.billing_house_no}
                      helperText={errors.billing_house_no?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="billing_address_additional"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("Address Addition")}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.billing_address_additional}
                      helperText={errors.billing_address_additional?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="billing_postal_code"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("Postal Code")}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.billing_postal_code}
                      helperText={errors.billing_postal_code?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="billing_city"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("City")}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.billing_city}
                      helperText={errors.billing_city?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}

          <Typography
            variant="h5"
            sx={{ fontSize: "1.1667rem", fontWeight: 700, margin: "20px 0" }}
          >
            {t("Shipping Method")}
          </Typography>
          <FormControl component="fieldset" className="shipping_radio_group">
            <RadioGroup>
              <FormControlLabel
                value="standard"
                control={<Radio checked />}
                label={t("Standard Shipping")}
              />
              {/* Add more shipping methods if needed */}
            </RadioGroup>
          </FormControl>

          <Typography
            variant="h5"
            sx={{
              fontSize: "1.1667rem",
              fontWeight: 700,
              margin: "20px 0 5px",
            }}
          >
            {t("Payment")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "0.857143rem", mb: "20px" }}
          >
            {t("All transactions are secure and encrypted.")}
          </Typography>
          <PayPalScriptProvider
            options={{
              clientId: paypalDetails?.clientId,
              components: "buttons,card-fields", //`${paymentMethod === 'paypal' ? "buttons" : 'card-fields'}`,
              currency: "EUR",
              intent: "capture",
            }}
          >
            <Box>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  className="payment_card_row"
                  onChange={(e) => handlePaymentMethodChange(e)}
                  value={paymentMethod}
                >
                  <Box className="payment_card" flexDirection={"column"}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon icon="streamline:credit-card-1-solid" />
                      <FormControlLabel
                        value="credit_card"
                        control={<Radio />}
                        label={t("Credit Card")}
                      />
                    </Box>

                    {paymentMethod === "credit_card" && (
                      <PayPalCardFieldsProvider
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                      >
                        <PayPalNumberField />
                        <PayPalExpiryField />
                        <PayPalCVVField />
                        <SubmitPayment
                          isPaying={isPaying}
                          setIsPaying={setIsPaying}
                        />
                      </PayPalCardFieldsProvider>
                    )}
                  </Box>
                  <Box className="payment_card">
                    <Icon icon="logos:paypal" />
                    <FormControlLabel
                      value="paypal"
                      control={<Radio />}
                      label={t("PayPal")}
                    />
                  </Box>
                  <Box className="payment_card">
                    <Icon icon="simple-icons:klarna" style={{color:'#000000', background:'#ffa8cd', padding: "5px"}}  />
                    <FormControlLabel
                      value="stripe"
                      control={<Radio />}
                      label={t("Klarna / Stripe")}
                    />
                  </Box>
                  {/* <Box className="payment_card">
                    <Icon icon="simple-icons:klarna" />
                    <FormControlLabel
                      value="klarna"
                      control={<Radio />}
                      label={t("Klarna")}
                    />
                  </Box> */}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box className="checkout_terms">
              <FormControlLabel
                control={
                  <Checkbox
                    name="acceptTerms"
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                  />
                }
                label={
                  <>
                    {t(
                      "Yes, I would like to receive updates, special offers and promotional emails from ApothekePremium. I know that I can unsubscribe at any time. For more details, please read our"
                    )}{" "}
                    <a href="/privacy-policy">{t("Privacy Policy")}</a>
                  </>
                }
              />
            </Box>
            {paymentMethod === "paypal" ? (
              <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
                style={{
                  shape: "pill", // Makes the button round
                }}
              />
            ) : (
              <Button
                className="button1"
                variant="contained"
                fullWidth
                onClick={handleClickPayNow}
              >
                {t("Pay Now")}
              </Button>
            )}
          </PayPalScriptProvider>
        </Box>
      </Grid>

      {/* Right Side - Order Summary */}
      <Grid className="checkout_products">
        {cartItems?.length > 0 && (
          <Box>
            {cartItems?.map((item, index) => (
              <>
                <Box className="osProduct_row" key={`ccartItem${index}`}>
                  <div className="osProduct_image">
                    <div className="osProduct_imgbx">
                      <img src={item?.image} alt={item?.productName} />
                      <span className="quantity">{item?.quantity}</span>
                    </div>
                  </div>
                  <Box className="osProduct_content">
                    <Box className="osProduct_name">
                      <Typography className="osProduct_title" variant="h6">
                        {item?.productName}- x{item?.quantity}
                      </Typography>
                      <Typography className="osProduct_price" variant="">
                        € {item?.productPrice}
                      </Typography>
                    </Box>
                    <Typography>
                      {t("Strength")}: {item?.selectedStrength}
                    </Typography>
                    <Typography>
                      {t("Quantity in package")}: {item?.selectedQuantity}
                    </Typography>
                  </Box>
                </Box>
              </>
            ))}
          </Box>
        )}
        <Box className="cart_coupon_code_row">
          <Box className="cart_coupon_code">
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={(e) => toggleVoucher(showVoucher)}
            >
              {t("Do you have a voucher code?")}
            </Typography>
            <IconButton onClick={(e) => toggleVoucher(showVoucher)}>
              <Icon icon={showVoucher ? "fe:arrow-up" : "fe:arrow-down"} />
            </IconButton>
          </Box>
          {showVoucher && (
            <Box className="cart_coupon_code_form">
              <TextField
                sx={{ height: "3rem", flexGrow: "1" }}
                name="voucherCode"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e?.target?.value)}
              />
              <Button
                variant="contained"
                onClick={() => applyCouponCode(voucherCode)}
              >
                {t("Input")}
              </Button>
            </Box>
          )}
        </Box>
        <Divider sx={{ m: "20px 0px" }} />
        <Box className="order_shipment">
          <Typography>
            <span>{t("Shipping")}:</span>{" "}
            <span>
              <b>{t("Free Shipping")}</b>
            </span>
          </Typography>
          {discountAmount > 0 && (
            <Typography>
              <span>{t("Discount")}: </span>
              <span>
                <b>€ {discountAmount > 0 ? `- ${discountAmount}` : 0} </b>
              </span>
            </Typography>
          )}
          <Typography>
            <span>{t("Prescription fee")}:</span>{" "}
            <span>
              <b>€ {`${totalPrescriptionFee} `}</b>
            </span>
          </Typography>

          <Divider sx={{ m: "20px 0px" }} />
          <Typography>
            <span>{t("Total")}:</span>{" "}
            <span>
              <b>€ {`${(totalAmount - discountAmount - 0)?.toFixed(2)} `}</b>
            </span>
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default CheckoutPage;
